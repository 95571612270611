import '@shopify/polaris/build/esm/styles.css';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import { login } from '~/shopify.server';
import { ErrorBoundaryFallback } from '~/components/error-boundary';
import newrelic from 'newrelic';
import * as Sentry from '@sentry/remix';

export const loader = async ({ request }: LoaderFunctionArgs) => {
    const errors = await login(request);

    newrelic.noticeError(new Error(`Login error: ${JSON.stringify(errors)}`));
    Sentry.captureException(new Error(`Login error: ${JSON.stringify(errors)}`));

    return json(null);
};

export default function Auth() {
    return <ErrorBoundaryFallback />;
}
